<template>
  <b-container v-if="renderPage">
    <b-overlay
      :show="showLoading"
      rounded="lg"
      variant="white"
      :opacity="1"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>Lançando Extrato, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-card title="Lançamento de Extrato">
        <validation-observer
          #default="{invalid}"
          ref="simpleRules"
        >
          <b-form
            novalidate
            class="needs-validation"
            @submit.prevent
          >
            <b-row>
              <b-col
                sm="12"
                lg="6"
                md="6"
              >
                <label for="v-ec">EC: </label>
                <validation-provider
                  #default="{ errors }"
                  name="EC"
                  rules="required"
                >
                  <b-form-tags
                    v-model="selectedEC"
                    remove-on-delete
                    placeholder="Adicione os ECs"
                    input-id="tags-limit"
                    class="ec-tag"
                    disable-add-button
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="mt-1">
                  <b-form-group
                    label="Valor:"
                    label-for="charge"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Valor"
                      rules="required|value"
                    >
                      <b-form-input
                        id="charge"
                        v-model="value"
                        v-money
                        placeholder="informe o Valor"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                sm="12"
                lg="6"
                md="6"
                class="mb-1"
              >
                <label for="v-diretor">Descrição: </label>
                <validation-provider
                  #default="{ errors }"
                  name="Descrição"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-no-resize"
                    v-model="description"
                    rows="4"
                    :state="errors.length > 0 ? false : null"
                    no-resize
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                md="2"
                sm="6"
              >
                <b-button
                  variant="primary"
                  block
                  :disabled="invalid"
                  @click="callModal"
                >Lançar</b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BIcon,
  BForm,
  BFormInput,
  BOverlay,
  BRow,
  BSpinner,
  BFormTextarea,
  BFormGroup,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'

import { postData } from '@/service/statement/statement'

export default {
  components: {
    BOverlay,
    BSpinner,
    BIcon,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    BCol,
    BRow,
    BCard,
    BContainer,
    BForm,
    BFormTags,
    BFormTextarea,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedEC: [],
      renderPage: true,
      showLoading: false,
      required,
      sectorSelected: null,
      profileSelected: null,
      managerSelected: null,
      email,
      manager: null,
      profile: null,
      userName: null,
      userEmail: null,
      selectType: 2,
      optionsType: [
        { value: 2, text: 'Crédito' },
        { value: 3, text: 'Débito' },
      ],
      value: 0.01,
      description: '',
    }
  },
  watch: {

  },
  methods: {
    async callModal() {
      await this.$bvModal
        .msgBoxConfirm('Deseja realmente Lançar o Extrato?', {
          cancelVariant: 'outline-secondary',
          centered: true,
          okTitle: 'Sim',
          cancelTitle: 'Não',
        })
        .then(value => {
          if (value) {
            this.statementPosting()
          }
        })
    },

    responseModal(message, icon) {
      this.$swal({
        text: message,
        icon: icon === 'success' ? 'success' : 'error',
        timer: '1750',
        showConfirmButton: false,
        buttonsStyling: false,
      })
    },

    async statementPosting() {
      this.showLoading = true
      const value = +this.value.replace(/[R$.]/g, '').replace(',', '.')

      const { status } = await postData(value, this.description, this.selectedEC)

      if (status === 200 || status === 201) {
        this.selectedEC = []
        this.value = 0.01
        this.description = ''
        this.$nextTick(() => this.$refs.simpleRules.reset());
        this.responseModal('Lançamento Concluído com Sucesso', 'success')
      } else {
        this.responseModal('Falha ao Lançar o Extrato', 'error')
      }
      this.showLoading = false
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/pages/new-client.scss";

.vs__deselect {
    fill: rgba(255, 255, 255, 0.9) !important;
}

textarea {
    height: 114px !important;
}

.ec-tag {
  padding: 0.51rem 1rem !important;
}

.b-form-tags-button {
  display: none !important;
}
</style>
